export default [
  {
    path: '/game',
    name: 'game_index',
    meta: {
      title: '游戏主页'
    },
    component: () => import(/* webpackChunkName: 'GameIndex' */ '@/views/Game')
  },
  {
    path: '/game/:id',
    name: 'game_detail',
    props: true,
    meta: {
      title: '游戏详情页'
    },
    component: () => import(/* webpackChunkName: 'GameDetail' */ '@/views/Game/detail')
  }
]
