'use strict'

import axios from 'axios'
import qs from 'qs'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
const APP_PREFIX = '/api'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

const config = {
  baseURL: `${process.env.VUE_APP_BASE_URL}${APP_PREFIX}`,
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  timeout: 60 * 1000 // Timeout
  // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (cfg) {
    // Do something before request is sent
    if (cfg.method === 'post') {
      cfg.data = qs.stringify(cfg.data)
    }
    return cfg
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  res => {
    // Do something with response data
    if (res.status !== 200) return Promise.reject(res)
    // 这里是后端操作返回的
    const { data: { error, messages } } = res
    if (error) {
      messages.forEach(message => console.error({ message }))
    }
    return Promise.resolve(res.data)
  },
  err => {
    // Do something with response error
    const { response: res } = err
    const { data: { error, messages } } = res
    if (error) {
      messages.forEach(message => console.error({ message }))
    }
    return Promise.reject(res.data)
  }
)

export default _axios
