const modules = {
  openUrl (url) {
    window.open(url)
  },
  github: {
    goToMyRepo (repoName) {
      window.open(`https://github.com/evgo2017/${repoName}`)
    },
    goToMyRepoReleases (repoName) {
      window.open(`https://github.com/evgo2017/${repoName}/releases`)
    }
  }
}
const context = require.context('./', false, /.js$/)

context.keys().map(module => {
  if (module === './index.js') {
    return
  }
  const name = module.replace(/(\.\/|\.js)/g, '')
  modules[name] = context(module).default
})

export default modules
