export default [
  {
    path: '/video',
    name: 'video_index',
    meta: {
      title: '影视主页'
    },
    component: () => import(/* webpackChunkName: 'VideoIndex' */ '@/views/Video')
  }
]
