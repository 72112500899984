export default [
  {
    path: '/sentence',
    name: 'sentence_index',
    meta: {
      title: '说说'
    },
    component: () => import(/* webpackChunkName: 'SentenceIndex' */ '@/views/Sentence')
  }
]
