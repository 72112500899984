export default [
  {
    path: '',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: () => import(/* webpackChunkName: 'HomeIndex' */ '@/views/Home')
  }
]
