export default [
  {
    path: '/book',
    name: 'book_index',
    meta: {
      title: '书籍主页'
    },
    component: () => import(/* webpackChunkName: 'BookIndex' */ '@/views/Book')
  }
]
