<template>
<v-app>
  <NavigationDrawerPc v-if="isPc"></NavigationDrawerPc>

  <v-app-bar app flat short hide-on-scroll outlined style="border-width: 0 0 thin;">
    <v-list-item-avatar v-if="!isPc" style="position: relative; left: -8px">
      <v-img src="/logo.png" @click.stop="drawer = !drawer"></v-img>
    </v-list-item-avatar>

    <v-spacer></v-spacer>

    <v-text-field
      solo-inverted
      flat
      clearable
      dense
      hide-details
      prepend-inner-icon="fa-search"
      class="mr-4 rounded-xl"
      label="Search"
    ></v-text-field>

    <v-icon small>fa-forward</v-icon>
  </v-app-bar>

  <v-main>
    <NavigationDrawerPhone v-if="!isPc" :drawer="drawer"></NavigationDrawerPhone>
    <router-view></router-view>
  </v-main>

  <SideButtons
    v-move-drag
    style="bottom: 20px; right: 20px; z-index: 999">
  </SideButtons>

  <v-footer inset class="d-flex align-center justify-center">
    <div>
      <span class="caption">© 2020 - evgo - <a href="https://beian.miit.gov.cn">陕ICP备19003938号-1</a></span>
    </div>
  </v-footer>
</v-app>
</template>

<script>
import SideButtons from '@/components/base/SideButtons.vue'
import NavigationDrawerPc from '@/layouts/NavigationDrawer/NavigationDrawerPc'
import NavigationDrawerPhone from '@/layouts/NavigationDrawer/NavigationDrawerPhone'

export default {
  name: 'App',
  components: {
    NavigationDrawerPc,
    NavigationDrawerPhone,
    SideButtons
  },
  data () {
    return {
      drawer: false
    }
  },
  computed: {
    isPc () {
      return this.$vuetify.breakpoint.smAndUp
    }
  }
}
</script>

<style lang="scss">
  .hasBeforeIcon {
    &:before {
      color: #555;
      font-family: FontAwesome;
      margin-right: .15em;
    }
  }
  .hasHoverUnderline {
    &:hover {
      text-decoration: underline;
    }
  }
  .text-subtitle-3 {
    font-size: .85rem;
  }
  .full-height {
    height: 100%;
  }
  .full-width {
    width: 100%;
  }
  .href-a {
    color: #1976D2;
    cursor: pointer;
    text-decoration: underline;
  }
</style>
