export default [
  {
    path: '/link',
    name: 'link_index',
    meta: {
      title: '联系主页'
    },
    component: () => import(/* webpackChunkName: 'LinkIndex' */ '@/views/Link')
  }
]
