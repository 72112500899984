import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import zhHans from 'vuetify/es5/locale/zh-Hans'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'font-awesome/css/font-awesome.css'
import store from '@/store'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: store.getters['customConfigs/isDark'],
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  lang: {
    locales: { zhHans },
    current: 'zh-Hans'
  },
  icons: {
    iconfont: 'fa4'
  }
})
