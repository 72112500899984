// 自动注册文件夹下所有模块
const modules = {}
const modulesContext = require.context('./', false, /.js$/)
modulesContext.keys().map(module => {
  const name = module.replace(/(\.\/|\.js)/g, '')
  if (name === 'index') {
    return
  }
  modules[name] = modulesContext(module).default
})
