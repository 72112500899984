<template>
  <v-speed-dial
    v-model="fab">
    <template v-slot:activator>
      <v-btn color="blue darken-2" dark fab @click="$vuetify.goTo(-9999, { easing: 'easeInOutCubic' })">
        <v-icon>fa-angle-up</v-icon>
      </v-btn>
    </template>
    <!-- <v-btn v-show="isScroll" fab dark small color="primary" @click="$vuetify.goTo(-9999, { easing: 'easeInOutCubic' })">
      <v-icon>fa-angle-up</v-icon>
    </v-btn>
    <v-btn fab dark small color="green">
      <v-icon>fa-comment</v-icon>
    </v-btn> -->
  </v-speed-dial>
</template>

<script>
export default {
  name: 'SideButtons',
  data () {
    return {
      fab: false,
      isScroll: false
    }
  },
  methods: {
  }
}
</script>
