import axios from '../index'
// import '../mock'

export default {
  Game: {
    getList: () => axios.get('/game'),
    getDetail: (id) => axios.get(`/game/${id}`)
  },
  Label: {
    list: (params) => axios.get('/labels', { params })
  },
  Category: {
    list: (params) => axios.get('/categories', { params })
  },
  Sentence: {
    list: (params) => axios.get('/sentences', { params })
  },
  Plan: {
    list: (params) => axios.get('/plans', { params })
  },
  Board: {
    list: (params) => axios.get('/boards', { params })
  },
  Timeline: {
    list: (params) => axios.get('/timelines', { params })
  },
  Blog: {
    get: (id) => axios.get(`/blog/${id}`),
    list: (params) => axios.get('/blogs', { params })
  }
}
