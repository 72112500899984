export default {
  getAgencyFromArray (rows) {
    const agency = {}
    rows.map(row => {
      const { pId } = row
      if (!agency[pId]) {
        agency[pId] = []
      }
      agency[pId].push(row)
    })
    return agency
  },

  convertArray2Tree (rows) {
    if (!rows || rows.length === 0) {
      return []
    } else {
      const agency = this.getAgencyFromArray(rows)
      return mergeTree(agency)
    }

    function mergeTree (agency, pId = 'root') {
      const children = agency[pId]
      if (!children || children.length === 0) {
        return []
      }
      return children.sort((a, b) => a.order - b.order).map(child => {
        const { id, name } = child
        const children = mergeTree(agency, id)
        return {
          id,
          name,
          children: children.length > 0 ? children : undefined
        }
      })
    }
  },

  convertTree2Array (tree, pId = 'root') {
    if (!tree || tree.length === 0) {
      return []
    }
    const rows = []
    for (let order = 0; order < tree.length; order++) {
      const { id, name, children } = tree[order]
      rows.push({
        id,
        name,
        pId,
        order: order + 1
      })
      rows.push(...this.convertTree2Array(children, id))
    }
    return rows
  },

  getPathFromArray (rows, goalId) {
    if (!rows || rows.length === 0) {
      return []
    }
    const goal = rows.find(row => {
      return row.id === goalId
    })
    if (!goal) {
      return []
    }
    const { pId, id } = goal
    if (!pId) {
      return [id]
    } else {
      return [...this.getPathFromArray(rows, pId), id]
    }
  },

  getPathFromTree (tree, goalId) {
    if (!tree || tree.length === 0) {
      return []
    } else {
      const rows = this.convertTree2Array(tree)
      return this.getPathFromArray(rows, goalId)
    }
  }
}
