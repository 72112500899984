export default [
  {
    path: '/repo',
    name: 'repo_index',
    meta: {
      title: '作品列表'
    },
    component: () => import(/* webpackChunkName: 'repoIndex' */ '@/views/Repo/index')
  },
  {
    path: '/repo/scoreboard',
    name: 'scoreboard',
    meta: {
      title: 'scoreboard'
    },
    component: () => import(/* webpackChunkName: 'repoDetail' */ '@/views/Repo/detail/scoreboard')
  },
  {
    path: '/repo/office2pdf',
    name: 'office2pdf',
    meta: {
      title: 'Office2PDF'
    },
    component: () => import(/* webpackChunkName: 'repoDetail' */ '@/views/Repo/detail/office2pdf')
  },
  {
    path: '/repo/typora-theme-orange-heart',
    name: 'typora-theme-orange-heart',
    meta: {
      title: 'typora-theme-orange-heart'
    },
    component: () => import(/* webpackChunkName: 'repoDetail' */ '@/views/Repo/detail/typora-theme-orange-heart')
  },
  {
    path: '/repo/wecom-api',
    name: 'wecom-api',
    meta: {
      title: 'wecom-api'
    },
    component: () => import(/* webpackChunkName: 'repoDetail' */ '@/views/Repo/detail/wecom-api')
  },
  {
    path: '/repo/vue-move-drag',
    name: 'vue-move-drag',
    meta: {
      title: 'vue-move-drag'
    },
    component: () => import(/* webpackChunkName: 'repoDetail' */ '@/views/Repo/detail/vue-move-drag')
  },
  {
    path: '/repo/picgo-plugin-folder-name',
    name: 'picgo-plugin-folder-name',
    meta: {
      title: 'picgo-plugin-folder-name'
    },
    component: () => import(/* webpackChunkName: 'repoDetail' */ '@/views/Repo/detail/picgo-plugin-folder-name')
  },
  {
    path: '/repo/vue-live2d',
    name: 'vue-live2d',
    meta: {
      title: 'vue-live2d'
    },
    component: () => import(/* webpackChunkName: 'repoDetail' */ '@/views/Repo/detail/vue-live2d')
  }
]
