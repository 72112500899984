<template>
  <v-navigation-drawer width="220" app permanent :mini-variant="mini">
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="/logo.png" @click.stop="mini = !mini"></v-img>
      </v-list-item-avatar>
      <v-list-item-title>Evgo</v-list-item-title>
      <v-btn icon @click.stop="mini = !mini">
        <v-icon>fa-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <NavigationDrawerList></NavigationDrawerList>

    <template v-slot:append>
      <NavigationDrawerDarkItem></NavigationDrawerDarkItem>
    </template>
  </v-navigation-drawer>
</template>

<script>
import NavigationDrawerDarkItem from '@/layouts/NavigationDrawer/NavigationDrawerDarkItem.vue'
import NavigationDrawerList from '@/layouts/NavigationDrawer/NavigationDrawerList.vue'

export default {
  name: 'NavigationDrawerPc',
  components: {
    NavigationDrawerDarkItem,
    NavigationDrawerList
  },
  data () {
    return {
      mini: false
    }
  }
}
</script>
