import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 自动注册 routes 文件夹下所有路由
const routesContext = require.context('./routes', false, /.js$/)
const routes = []
routesContext.keys().forEach(route => routes.push(...routesContext(route).default))

const RouterViewOnly = () => import(/* webpackChunkName: 'RouterViewOnly' */ '@/components/base/RouterViewOnly')

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    meta: {
      title: '首页'
    },
    component: RouterViewOnly,
    children: routes
  }]
})

export default router
