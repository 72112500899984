export default [
  {
    path: '/picture',
    name: 'picture_index',
    meta: {
      title: '图库主页'
    },
    component: () => import(/* webpackChunkName: 'PictureIndex' */ '@/views/Picture')
  }
]
