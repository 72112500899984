import Vue from 'vue'
import Vuex from 'vuex'
import Storage from './storage'

Vue.use(Vuex)

if (Storage.getLocalStorage() === null) {
  Storage.setLocalStorage({
    isDark: 0
  })
}

export default new Vuex.Store({
  state: {
    entityType: {
      blog: 'blog',
      book: 'book',
      video: 'video',
      game: 'game',
      sentence: 'sentence',
      repo: 'repo'
    },
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    customConfigs: {
      namespaced: true,
      state: {
      },
      getters: {
        isDark () {
          return !!parseInt(Storage.getLocalStorageAttr('isDark'))
        }
      },
      mutations: {
        changeIsDark (state, vuetify) {
          const newIsDark = !parseInt(Storage.getLocalStorageAttr('isDark'))
          vuetify.theme.dark = newIsDark
          Storage.setLocalStorageAttr('isDark', newIsDark ? '1' : '0')
        }
      }
    },
    navigation: {
      namespaced: true,
      state: {
        items: [
          {
            title: '首页 | Home',
            icon: 'fa-home',
            href: '/'
          },
          {
            title: '说说 | Sentence',
            icon: 'fa-comment',
            href: '/sentence'
          },
          // { title: '图库 | Picture', icon: 'fa-picture-o', href: '/picture' },
          {
            title: '文章 | Blog',
            icon: 'fa-pencil',
            href: '/blog'
          },
          {
            title: '作品 | Repo',
            icon: 'fa-github-alt',
            href: '/repo'
          },
          {
            title: '书籍 | Book',
            icon: 'fa-book',
            href: '/book'
          },
          {
            title: '影视 | Video',
            icon: 'fa-youtube-play',
            href: '/video'
          },
          {
            title: '游戏 | Game',
            icon: 'fa-gamepad',
            href: '/game'
          },
          {
            title: '联系 | Link',
            icon: 'fa-link',
            href: '/link'
          }
          // { title: '音乐 | Music', icon: 'fa-music', href: '/music' },
        ]
      }
    },
    repo: {
      namespaced: true,
      state: {
        items: {
          scoreboard: {
            name: '比赛记分牌',
            description: '微信小程序：模拟真实记分牌，支持大小比分、交换场地、查看每局比分、撤销、恢复等功能',
            imgName: 'scoreboard.jpg',
            routerName: 'scoreboard'
          },
          office2pdf: {
            name: 'office2pdf',
            description: 'Office 文件（Word、Excel、PPT）批量转为 PDF 文件',
            repoName: 'Office2PDF',
            imgName: 'office2pdf.png',
            routerName: 'office2pdf'
          },
          'typora-theme-orange-heart': {
            name: 'typora-theme-orange-heart',
            description: '一个 Typora 主题',
            repoName: 'typora-theme-orange-heart',
            imgName: 'typora-theme-orange-heart.png',
            routerName: 'typora-theme-orange-heart'
          },
          'vue-live2d': {
            name: 'vue-live2d',
            description: 'vue live2d 看板',
            repoName: 'vue-live2d',
            npmName: 'vue-live2d',
            imgName: 'vue-live2d.PNG',
            routerName: 'vue-live2d'
          },
          'wecom-api': {
            name: 'wecom-api',
            description: '企业微信 API，加解密库 WXBizMsgCrypt，消息推送',
            repoName: 'wecom-api',
            npmName: 'wecom-api',
            imgName: 'wecom-api.png',
            routerName: 'wecom-api'
          },
          'picgo-plugin-folder-name': {
            name: 'picgo-plugin-folder-name',
            description: '将文件的部分本地地址，作为部分云端地址。便于分文件夹管理文件',
            repoName: 'picgo-plugin-folder-name',
            npmName: 'picgo-plugin-folder-name',
            imgName: 'picgo-plugin-folder-name.png',
            routerName: 'picgo-plugin-folder-name'
          },
          'vue-move-drag': {
            name: 'vue-move-drag',
            description: '移动或拖拽一个 DOM 元素到其他位置',
            repoName: 'vue-move-drag',
            npmName: 'vue-move-drag',
            imgName: 'vue-move-drag.png',
            routerName: 'vue-move-drag'
          }
        }
      }
    }
  }
})
