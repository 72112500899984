export default [
  {
    path: '/blog',
    title: '文章',
    name: 'blog_index',
    meta: {
      title: '文章列表'
    },
    component: () => import(/* webpackChunkName: 'BlogIndex' */ '@/views/Blog')
  },
  {
    path: '/blog/:id',
    name: 'blog_detail',
    props: true,
    meta: {
      title: '文章详情页'
    },
    component: () => import(/* webpackChunkName: 'BlogDetail' */ '@/views/Blog/detail')
  }
]
