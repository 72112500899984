<template>
  <v-list>
    <v-list-item-group>
      <v-list-item @click="changeTheme">
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-list-item-icon>
              <v-icon v-on="on">fa-sun-o</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>夜间</v-list-item-title>
            </v-list-item-content>
          </template>
          <span>夜间</span>
        </v-tooltip>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  name: 'NavigationDrawerDarkItem',
  methods: {
    changeTheme () {
      this.$store.commit('customConfigs/changeIsDark', this.$vuetify)
    }
  }
}
</script>
