<template>
  <v-list dense shaped>
    <v-list-item
      v-for="item in $store.state.navigation.items"
      :key="item.title"
      @click="goHref(item.href)">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-list-item-icon>
            <v-icon v-on="on">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <span>{{ item.title }}</span>
      </v-tooltip>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'NavigationDrawerList',
  methods: {
    goHref (href) {
      const isUrl = this.$util.reg.isUrl(href)
      if (isUrl) {
        this.$util.openUrl(href)
      } else {
        this.$router.push({
          path: href
        })
      }
    }
  }
}
</script>
