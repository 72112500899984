'use strict'

import Vue from 'vue'
import axios from '@/http/index'

Plugin.install = function (Vue, options) {
  Vue.axios = axios
  window.axios = axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return axios
      }
    },
    $axios: {
      get () {
        return axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
