export default {
  key: 'evgo2017_website',

  /**
   * 设置 Cookies
   */

  setCookie (name, value) {
    const date = new Date()
    date.setTime(date.getTime() + 2 * 365 * 24 * 60 * 60 * 1000)
    document.cookie = name + '=' + escape(value) + ';path=/;' + 'expires=' + date.toGMTString() + ';'
  },

  getCookie (name) {
    const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
    const arr = document.cookie.match(reg)
    return arr ? unescape(arr[2]) : null
  },

  /**
  * 设置 Local Storage
  */

  getLocalStorageAttr (name) {
    return this.getLocalStorage()[name]
  },

  getLocalStorage () {
    return JSON.parse(window.localStorage.getItem(this.key))
  },

  setLocalStorageAttr (name, value) {
    this.setLocalStorage({
      [name]: value
    })
  },

  setLocalStorage (newValue = {}) {
    window.localStorage.setItem(this.key, JSON.stringify(Object.assign({}, newValue)))
  }
}
