<template>
  <v-navigation-drawer fixed temporary :value="drawer" style="height: calc(100vh - 56px); top: 56px;">
    <NavigationDrawerList></NavigationDrawerList>

    <template v-slot:append>
      <NavigationDrawerDarkItem></NavigationDrawerDarkItem>
    </template>
  </v-navigation-drawer>
</template>

<script>
import NavigationDrawerDarkItem from '@/layouts/NavigationDrawer/NavigationDrawerDarkItem.vue'
import NavigationDrawerList from '@/layouts/NavigationDrawer/NavigationDrawerList.vue'

export default {
  name: 'NavigationDrawerPhone',
  components: {
    NavigationDrawerDarkItem,
    NavigationDrawerList
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    }
  }
}
</script>
