import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/axios'
import http from './http/api/index'
import util from './util/index'
import infiniteScroll from 'vue-infinite-scroll'

import VueClipboard from 'vue-clipboard2'
// VuetifyDialog 要在 vuetify 之后
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import vueMoveDrag from 'vue-move-drag'

// TODO: vue hljs
import './directives'
import hljs from 'highlight.js'
Vue.directive('highlight', function (el) {
  const blocks = el.querySelectorAll('code')
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})

Vue.prototype.$http = http
Vue.prototype.$util = util
Vue.config.productionTip = false

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})
Vue.use(infiniteScroll)
Vue.use(vueMoveDrag)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
